<template>
  <CLink
    v-if="backBtn"
    :to="{name: 'contact_messages'}"
    translated
    :label="$t('views.action.back')"
    icon="arrow-left"
    class="btn-a-outline"
  />
  <CMessage :messages="message.base" />
  <div
    v-for="(item, index) in contact_messages"
    :key="index"
    :tabIndex="index"
    :autofocus="isFocusMessage(item.id)"
    class="mb-6"
  >
    <CContactMessage :contact-message="item" />
  </div>
  <div class="text-right">
    <CTextArea
      v-model="contact_message.body"
      :errors="message.body"
      :rows="5"
      outer-class="justify-self-end w-full sm:w-[500px]"
    />
    <CButton
      label="send"
      icon="send"
      @click.prevent="create"
    />
  </div>
</template>
<script>
import ApiRequestor from '../common/ApiRequestor'
export default {
  name: 'ContactMessageThread',
  props: {
    contactMessageId: [Number, String],
    backBtn: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      message: {},
      contact_messages: [],
      contact_message: {
        contact_message_id: this.idWatcher,
        body: null
      }
    }
  },
  computed: {
    idWatcher() {
      return this.$route.params.id || this.contactMessageId;
    },
    isLoggedIn() {
      return this.$store.state.auth.isLoggedIn;
    },
    contact_message_params(){
      return {
        contact_message_id: this.idWatcher,
        body: this.contact_message.body
      }
    }
  },
  watch: {
    idWatcher() {
      this.load()
    }
  },
  created() {
    if(this.isLoggedIn) {
      this.load()
    } else {
        ApiRequestor.redirectMember()
    }
  },
  methods: {
    isFocusMessage(id) {
      return id && id == this.$route.query.m ? true : false
    },
    load() {
      if(!this.idWatcher) {
        return
      }
      ApiRequestor.get('current_user/contact_messages', {contact_message_id: this.idWatcher} ).then(
        response => {
          this.contact_messages = response.data
          this.message = {}
        },
        error => {
          this.message = error.response.data
        }
      )
    },
    create(){
      ApiRequestor.post('current_user/contact_messages', {contact_message: this.contact_message_params} ).then(
        response => {
          this.contact_messages.push(response.data)
          this.contact_message.body = null
          this.message = {}
        },
        error => {
          this.message = error.response.data
        }
      )
    }
  },
}
</script>